<template>
  <div class="">
      <h3 class="pa-1">
        Dernieres sorties de chapitres
      </h3>
    <v-card elevation="0" v-if="ll.listEntity.length == 0"  v-for="n in 6"
            :color="`grey darken-2 `"
            :key="n"
    >
      <v-skeleton-loader
        class="mx-auto"
        :height="72"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-card>
    <div class="pa-lg-6 pa-sm-3">

      <template v-for="(item, index) in ll.listEntity">
      <v-card class="mt-3 " elevation="0" color="white"
      >
        <div class="d-flex flex-no-wrap justify-start">

          <v-card
            :to="'/scan/'+item.comicbook_seo+'/'+item.id"
            class="ma-3"
            width="125"
            rounded="0"
          >
            <v-img
              class="white--text align-end"
              :height="120"
              :src="item.cover_src_sm"
              :lazy-src="require('@/assets/images/holder.jpg')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 "
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card>

          <div style="width: 100%" >
            <v-card-title  class=" ">
             <router-link
               :to="'/scan/'+item.comicbook_seo+'/'+item.id"> {{ item.title }}</router-link>
            </v-card-title>

            <v-card-subtitle>
              {{ item.creationdate }} - <v-icon small>
              {{ icons.mdiEyeOutline }}
            </v-icon> {{ item.nbviews }}
            </v-card-subtitle>

            <v-card-actions class="justify-space-between">
              <b>#{{ item.number }}</b>  <v-chip
                v-if="item.product.price == 0"
                small
                color="success"
              >
                Free
              </v-chip>
              <template v-if="item.product.price > 0">
                <v-chip
                  small
                  color="warning"
                >
                  {{ item.product.price }} Credits
                </v-chip>
                Extrait {{ item.free_pages }} pages
              </template>
              <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
            </v-card-actions>
          </div>
        </div>
      </v-card>
        <v-divider></v-divider>
      </template>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'

export default {
  components: { Banner },
  setup() {
    const $vuetify = getVuetify()
    const items = ref([])
    const drawer = ref(false)
    const tab = ref('cb')
    const chapters = ref([])
    const ll = ref({listEntity:[]})
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 150
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })
    const init = () => {
      Drequest.api('lazyloading.chapter?dfilters=on&status:eq=1&dsort=id desc')
        .get(response => {
          console.log(response)
          ll.value = response
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,

      tab,
      items,
      ll,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
